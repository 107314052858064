import React, { Fragment } from 'react';
import SearchContext from '../../shared/SearchContext';

class Profile extends React.Component {
	static contextType = SearchContext.Context;

	componentDidMount() {
		document.title = `Profile | NSW Government-Health `;
		window.scrollTo(0, 0);
	}

	render() {
		const { history } = this.props;

		if (!history.location.state) { return (<h4 className="text-center">No provider available</h4>) }

		const result = history.location.state.result;
		const logo = result.fields.logo;
		const fileName = logo.substring(logo.lastIndexOf('/') + 1);
		const pdfFileName = fileName.split('.')[0] || '';
		const pdfURL = `https://storage.googleapis.com/ahs_pdf_bucket/${pdfFileName}.pdf`

		return (
			<Fragment>
				<article className="container pt-3">
					<div className="row" style={{ clear: "both" }}>
						<div className="header row m-0">
							<div className="col-9" onClick={history.goBack}>
								<i className="fas fa-arrow-left d-inline-block" />
								<p className="pl-3 pr-3 d-inline-block mb-0">Return to Search Results</p>
								{/* <p className="pl-3 d-inline-block mb-0">Specialist allied health services for schools </p> */}
							</div>
							<div className="col-3 text-right">
								<a href={pdfURL} download={result.title} target="_blank">
									<span className="download-link">
										<p className="pr-3 d-inline-block mb-0">Download</p>
										<i className="fas fa-file-download fa-lg" />
									</span>
								</a>
								<div />
							</div>
						</div>
						<div className="col-12 pt-4">
							<h3>{result.title}</h3>
							<h4 className="text-dark">{result.name}</h4>
							<span className="row pl-3"><b className="text-dark float-left mr-2">Trading Name:  </b><p className={"text-secondary mb-1"}>{result.fields.tradingName}</p></span>
							<span className="row pl-3"><b className="text-dark float-left mr-2">Address:  </b><p className={"text-secondary mb-1"}>{result.fields.address}</p></span>
							<div className="row result-container border-bottom py-0">
								<Contacts result={result} />
							</div>
							<div className="pt-3">
								<p >{result.fields.blurb}</p>
							</div>
							<div className="row result-container pt-0">
								<Regions result={result} />
							</div>
							<h4>Available Services:</h4>
							<div className="row result-container pt-0">
								<Services result={result} />
							</div>
							<div className="row result-container pt-0">
								<Audience result={result} />
								<ServiceMethod result={result} />
							</div>
						</div>
					</div>
				</article>
			</Fragment >
		);
	}
}

export default Profile;

const ServiceList = ({ list }) => {
	if (list == undefined || list.length === 0) return null;

	return (
		list && list.filter(item => item != null).map((item, index) => {
			const hasBullet = item && item.toString().includes('•');
			if (hasBullet) {
				return <div key={index} className="d-flex flex-row pl-3">
					<i>•</i>
					<p key={index} className="text-secondary pl-3 mb-1">{item.substring(1)}</p>
				</div>
			} else {
				const hasSubBullet = item && item.includes("    o    ");

				if (hasSubBullet) {
					return <div key={index} className="d-flex flex-row pl-5">
						<i>-</i>
						<p className="text-secondary pl-3 mb-1">{item.replace("    o    ", "")}</p>
					</div>
				} else {
					return <p key={index} className="pl-2 mb-1" style={{ whiteSpace: 'pre-wrap' }}>{item}</p>
				}
			}
		})
	)
}

const Services = ({ result }) => {
	const rawServices = JSON.parse(JSON.stringify(result.fields.services));
	const services = JSON.parse(rawServices);

	if (services == undefined || services.length === 0) return null;

	return (
		services && services.filter(item => item != null).map((item, index) => (
			<div key={index} className="service mt-2" >
				<h5>{item.name}</h5>
				<ServiceList list={item.details} />
			</div>
		))
	)
}

const Regions = ({ result }) => {
	const regions = JSON.parse(JSON.stringify(result.fields.regions));
	const coverageWithinRegion = JSON.parse(JSON.stringify(result.fields.coverageWithinRegion));

	if (regions == undefined || regions.length === 0) return null;

	return (
		<div>
			<h4>Coverage Regions:</h4>
			{
				regions && regions.filter(item => item != null).map((item, index) => {
					const coverage = coverageWithinRegion && coverageWithinRegion.filter(coverageRegion => coverageRegion.includes(item));

					return (
						<div key={index} className="d-flex flex-row pl-3">
							<i>•</i>
							<p key={index} className="text-secondary pl-3 mb-1">{coverage.length > 0 ? coverage[0] : item}</p>
						</div>
					)
				})
			}
		</div>
	)
}

const Contacts = ({ result }) => {
	const fields = result.fields;
	const rawContacts = JSON.parse(JSON.stringify(fields.contacts));
	const contacts = JSON.parse(rawContacts) || [];

	if (contacts.length > 0) {
		return contacts && contacts.filter(item => item != null).map((contact, index) => {
			return (
				<div key={index} className="result border-top py-2">
					{contact.name && <span className="row pl-3"><b className="text-dark float-left mr-2">Contact Name:  </b><p className={"text-secondary mb-1"}>{contact.name}</p></span>}
					{contact.telephone && <span className="row pl-3"><b className="text-dark float-left mr-2">Contact Phone:  </b><p className={"text-secondary mb-1"}>{contact.telephone}</p></span>}
					{contact.email && <span className="row pl-3">
						<div style={{ display: 'flex', overflow: 'hidden', cursor: 'pointer' }} className="pb-1">
							<b className="text-dark float-left mr-2">Email:  </b>
							<a href={"mailto:" + contact.email} className="text-secondary mb-1" target="_blank" title={contact.email}>{contact.email}</a>
						</div>
					</span>
					}
				</div>
			)
		})
	} else {
		return null;
	}
}

const Audience = ({ result }) => {
	const serviceAudience = JSON.parse(result.fields.serviceAudiences)
	if (serviceAudience == undefined || serviceAudience.length === 0) return null;

	return (
		<div className="service mt-4" >
			<h4>Who do they provide services to?</h4>
			{
				serviceAudience && serviceAudience.filter(item => item != null).map((item, index) => {
					return (
						<div key={index}>
							<p>{item.summary}</p>
							<ServiceList list={item.audiences} />
						</div>
					)
				})
			}
		</div>
	)
}

const ServiceMethod = ({ result }) => {
	const rawServiceMethods = JSON.parse(JSON.stringify(result.fields.serviceMethods));
	const serviceMethods = JSON.parse(rawServiceMethods);

	if (serviceMethods == undefined || serviceMethods.length === 0) return null;
	return (
		<div className="service mt-4" >
			<h4>How do they provide services?</h4>
			<ServiceList list={serviceMethods} />
		</div>
	)
}