import { ajaxFetch } from "../utils/utils";
import { getSiteOptions } from "../utils/meta";

const siteOptions = getSiteOptions();

const login = accessToken => (
	ajaxFetch(`${siteOptions.baseUrl}/access_token?access_token=${accessToken}`)
)

const isAuthorizedUser = () => {
	return window.sessionStorage.getItem('access_token') != null;
}

const clearSession = () => {
	window.sessionStorage.removeItem('access_token');
	window.sessionStorage.removeItem('user_name');
	window.sessionStorage.removeItem('facets');
	window.sessionStorage.removeItem('searchTerm');
	window.sessionStorage.removeItem('sortAlphabatically');
}

const getUserInfo = (accessToken) => {
	const settings = {
		"async": true,
		"crossDomain": true,
		// "url": 'https://sso.test.det.nsw.edu.au/sso/oauth2/userinfo',
		"url": 'https://graph.microsoft.com/oidc/userinfo',
		"method": 'GET',
		"headers": {
			"Authorization": `Bearer ${accessToken}`,
			"cache-control": 'no-cache',
		}
	}

	return ajaxFetch(settings);
}


export { login, isAuthorizedUser, getUserInfo, clearSession };

